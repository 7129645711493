<template>
  <div class="orderList container">
    <div class="searchBox">
      <el-button type="primary"
                 v-if="module_id_array.includes('135')"
                 @click="showAdd">添加模板</el-button>
    </div>
    <div class="table-box">
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }">
        <el-table-column label="模板ID"
                         prop="commission_tcm_id"
                         width="100"></el-table-column>
        <el-table-column label="模板名"
                         prop="commission_tcm_title"></el-table-column>
        <el-table-column label="最后编辑时间"
                         prop="update_time"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">正常</span>
            <span class="red"
                  v-if="scope.row.status == 1">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="250">
          <template slot-scope="scope">
            <el-button type="text"
                       v-if="scope.row.status == 1 && module_id_array.includes('136')"
                       @click="editCommissionTcmStatus(0, scope.row.commission_tcm_id)">启用
            </el-button>
            <el-button type="text"
                       v-if="scope.row.status == 0 && module_id_array.includes('136')"
                       @click="editCommissionTcmStatus(1, scope.row.commission_tcm_id)">禁用
            </el-button>
            <el-button type="text"
                       v-if="module_id_array.includes('135')"
                       @click="showEdit(scope.row)">编辑 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="page"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="10"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total"
                   style="margin: 30px 0 40px 0"></el-pagination>

    <!-- 确认比例 -->
    <el-dialog title="设置提成比例"
               :visible.sync="biliShow"
               width="600px"
               class="recommendDialog">
      <div class="bb">
        <div class="flex align-center"
             style="margin: 10px 0">
          <div style="width: 120px">模板名称：</div>
          <el-input v-model="commission_tcm_title"
                    label="模板名称"
                    style="width: 200px"></el-input>
        </div>
        <div class="flex align-center"
             style="margin: 10px 0">
          <div style="width: 120px">一级佣金比例：</div>
          <el-input-number v-model="commission_one_proportion"
                           :min="0"
                           :max="99"
                           label="请设置"
                           :controls="false"></el-input-number>
          %
        </div>
        <div class="flex align-center"
             style="margin: 10px 0">
          <div style="width: 120px">二级佣金比例：</div>
          <el-input-number v-model="commission_two_proportion"
                           :min="0"
                           :max="99"
                           label="请设置"
                           :controls="false"></el-input-number>%
        </div>
        <div class="flex align-center"
             style="margin: 10px 0">
          <div style="width: 120px">自购折扣：</div>
          <el-input-number v-model="commission_self_proportion"
                           :min="0"
                           :max="10"
                           label="请设置"
                           :controls="false"></el-input-number>折
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="biliShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="saveCommissionTcm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "orderList",
  data() {
    return {
      total: 0,
      page: 1,
      pageNumber: 10,
      biliShow: false,
      tableData: [],
      commission_tcm_id: "", //选择的模板id
      commission_tcm_title: "",
      commission_one_proportion: undefined,
      commission_two_proportion: undefined,
      commission_self_proportion: undefined,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  components: {},
  created() {
    this.getList();
  },
  activated() { },
  methods: {
    getList() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
      };
      this.axios
        .post("/store/commission/getCommissionTcmList", data)
        .then((res) => {
          this.total = res.data.total;
          let list = res.data.list;
          list.forEach((ele) => {
            ele.update_time = this.dayjs
              .unix(ele.update_time)
              .format("YYYY-MM-DD HH:mm:ss");
          });
          this.tableData = list;
        });
    },

    reset() {
      this.page = 1;
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },

    //更新模板
    saveCommissionTcm() {
      if (
        !(
          this.commission_one_proportion &&
          this.commission_two_proportion &&
          this.commission_self_proportion &&
          this.commission_tcm_title
        )
      ) {
        this.$message.error("请选择模板或填写比例");
        return;
      }
      let data = {
        commission_one_proportion: this.commission_one_proportion,
        commission_two_proportion: this.commission_two_proportion,
        commission_self_proportion: this.commission_self_proportion,
        commission_tcm_title: this.commission_tcm_title,
        commission_tcm_id: this.commission_tcm_id,
      };
      this.axios
        .post("/store/commission/saveCommissionTcm", data)
        .then((res) => {
          this.biliShow = false;
          this.$message.success("更新成功");
          this.getList();
        });
    },
    showEdit(item) {
      this.commission_one_proportion = item.commission_one_proportion;
      this.commission_two_proportion = item.commission_two_proportion;
      this.commission_self_proportion = item.commission_self_proportion;
      this.commission_tcm_title = item.commission_tcm_title;
      this.commission_tcm_id = item.commission_tcm_id
      this.biliShow = true;
    },
    showAdd() {
      this.biliShow = true;
      this.commission_one_proportion = undefined;
      this.commission_two_proportion = undefined;
      this.commission_self_proportion = undefined;
      this.commission_tcm_title = "";
      this.commission_tcm_id = ""
    },
    editCommissionTcmStatus(status, id) {
      let data = {
        status,
        commission_tcm_id: id,
      };
      this.axios
        .post("/store/commission/editCommissionTcmStatus", data)
        .then((res) => {
          this.$message.success("更新成功");
          this.getList();
        });
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
<style lang='scss'>
.orderList {
  .el-range-editor {
    width: 250px;
  }
}
.recommendDialog {
  .content {
    padding: 15px;
  }
  .list {
    height: 500px;
    overflow: auto;
    .box {
      margin: 15px 0;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #cccccc;
      .el-checkbox {
        align-self: center;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .d1 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          margin-right: 10px;
        }
      }
      .d2 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          color: #a0918c;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>